import '../helpers/helpers';

import * as slickSliders from '../functions/slickSliders';
import * as googleMap from '../functions/googleMap';
import * as templateScripts from '../functions/templateScripts';
import * as scrollEvent from '../eventHandlers/scrollEvent';
import * as adaptHeight from '../functions/adaptHeight';

import * as skrollr from 'skrollr';

document.addEventListener('DOMContentLoaded', function(){

	var $fancybox = $('a.fancybox'),
		$fancyboxThumbs = $('a.fancybox-thumbs'),
		$map = document.getElementById('map'),
		footerHeight = $('footer').height(),
		$odometer = document.querySelectorAll('.odometer'),
		$mainSlider = document.querySelectorAll('.main-slider'),
		isFirefox = typeof InstallTrigger !== 'undefined',
		isIE = /*@cc_on!@*/false || !!document.documentMode,
		isEdge = !isIE && !!window.StyleMedia;

	templateScripts.init();

	if($mainSlider){
		slickSliders.init();
	}

	if($fancybox.length) {
		$fancybox.fancybox({
			helpers: {
				overlay: {
					locked: false
				}
			}
		});
	}

	if($fancyboxThumbs.length) {
		$fancybox.fancybox({
			afterLoad: function(){
                $('body').addClass('body-fancyboxed');
            },
            beforeClose: function(){
                $('body').removeClass('body-fancyboxed');
            },
			helpers: {
				thumbs: {
					width: 130,
					height: 75
				},
				overlay: {
					locked: false
				}
			}
		});
	}

	if($map) {
		googleMap.init();
	}

	if($odometer){
		scrollEvent.init();
	}

	$('.reservation-online').on('click', function(){
		document.querySelector('.popup-hld').classList.add('js-show');
	});

	$('.close-btn, .popup-blank').on('click', function(){
		document.querySelector('.popup-hld').classList.remove('js-show');
	});

	if(isFirefox){
		$('html').addClass('mozilla');
	}

	if(isIE || isEdge) {
		$('html').addClass('ie');	
	}
});

function checkViewPort() {
	if($(window).width() > 1024 && $('html').hasClass('mobile-view')){
		$('html').removeClass('mobile-view');
	} else if ($(window).width() <= 1024 && !$('html').hasClass('mobile-view')){
		$('html').addClass('mobile-view');
	}
}

$(window).on('load', function() {
	var $adaptHeight = $('.column-section.adapt-height'),
		$fullscreenView = $('.fullscreen-view'),
		$videobg = document.querySelector('.videobg');

	checkViewPort();
	
	if(!$('html').hasClass('tablet-view') && !$('html').hasClass('mobile-view')){
		var mySkrollr = skrollr.init({
			edgeStrategy: 'set',
			easing: 'cubic',
			smoothScrolling: false,
			forceHeight: false
		});
	}

	if($adaptHeight.length || $fullscreenView.length) {
		adaptHeight.init();
	}
});

$(window).resize(function() { 
	var $adaptHeight = $('.column-section.adapt-height'),
		$fullscreenView = $('.fullscreen-view');

	if($adaptHeight.length || $fullscreenView.length) {
		adaptHeight.init();
	}

	checkViewPort();

	if($(window).width() > 1023) { $('#menu').removeClass('menu-is-open'); }
});

AOS.init({
	disable: 'mobile',
	easing: 'ease-out',
	duration: 1000
});
import * as isElementInViewport from '../helpers/isElementInViewport';

export function init() {

	var $body = document.getElementsByTagName('body')[0];

	$('.odometer').each(function(i) {

		var self = $(this),
			attr = self.attr('data-num');

		if(typeof attr !== 'undefined' && attr.length && isElementInViewport.init(self, 0) && !self.hasClass('odometer-init')) {
			setTimeout(function() {
				self.html(parseInt(attr));
			}, 300 * i);

			self.addClass('odometer-init');
		}
		
	});

};
export function init($el = false, offset = 100) {

    let el = false;
    if ($el.length) {
        el = $el.get(0);
    } else {
        return false;
    }

    const rect = el.getBoundingClientRect();
    const windowH = window.innerHeight || document.documentElement.clientHeight;

    return (
        rect.top < (windowH - offset)
        && rect.bottom > offset
    );
	
};
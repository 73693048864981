export function init() {

	var $mainSlider = document.getElementById('banner-slider'),
		$carousel = document.querySelector('.carousel'),
		$stickyCarousel = document.querySelector('.sticky-carousel'),
		$spacedCarousel = document.querySelector('.spaced-carousel'),
		$testimonialsSlider = document.querySelector('.testimonials-slider'),
		$galleryBig = document.querySelector('.gallery-big'),
		$sliderBox = document.querySelector('.slider-box');

	if($mainSlider) {
		if($mainSlider.hasClass('banner-slider--arrows') && $('.banner-item').length > 1){
			$('.banner-slider--arrows').on('init', function(event, slick, currentSlide, nextSlide){
				var $activeSlide = $('#banner .slick-track .slick-active');

				$activeSlide.find('.slider-content').removeClass('hide-elem').addClass('animate-init');
			});

			$('.banner-slider--arrows').on('afterChange', function(event, slick, currentSlide, nextSlide){
				var $activeSlide = $('#banner .slick-track .slick-active');

				$activeSlide.prev().find('.slider-content').removeClass('animate-init').addClass('hide-elem');

				$activeSlide.next().find('.slider-content').removeClass('animate-init').addClass('hide-elem');

				$activeSlide.find('.slider-content').addClass('animate-init').removeClass('hide-elem');
			});

			$('.banner-slider--arrows').slick({
				infinite: true,
				speed: 1000,
				slidesToShow: 1,
				autoplay: true,
				autoplaySpeed: 5000,
				fade: true,
				useTransform: true,
				cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
				dots: false,
				arrows: true,
				prevArrow: '<span class="slick-prev"></span>',
				nextArrow: '<span class="slick-next"></span>'
			});
		} else if($mainSlider.hasClass('banner-slider--dots') && $('.banner-item').length > 1) {
			$('.banner-slider--dots').slick({
				infinite: true,
				speed: 1000,
				slidesToShow: 1,
				autoplay: true,
				autoplaySpeed: 5000,
				fade: true,
				useTransform: true,
				cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
				dots: true,
				arrows: false
			});
		}
	}

	if($carousel) {
		$('.carousel').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 5,
			slidesToScroll: 3,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: false,
			responsive: [
		    {
		      	breakpoint: 1024,
		      	settings: {
			        slidesToShow: 3,
			        slidesToScroll: 3,
			        infinite: true,
			        dots: true
		      	}
		   	},
		    {
		      	breakpoint: 480,
		      	settings: {
			        slidesToShow: 2,
			        slidesToScroll: 2
		      	}
		    }]
		});
	}

	if($stickyCarousel) {
		$('.sticky-carousel').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 4,
			slidesToScroll: 1,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: true,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			responsive: [
		    {
		      	breakpoint: 1024,
		      	settings: {
			        slidesToShow: 3,
			        slidesToScroll: 3,
			        infinite: true,
			        dots: true
		      	}
		   	},
		    {
		      	breakpoint: 480,
		      	settings: {
			        slidesToShow: 2,
			        slidesToScroll: 1
		      	}
		    }]
		});
	}

	if($spacedCarousel) {
		$('.spaced-carousel').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 4,
			slidesToScroll: 2,
			autoplay: false,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: true,
			arrows: false,
			responsive: [
		    {
		      	breakpoint: 1024,
		      	settings: {
			        slidesToShow: 3,
			        slidesToScroll: 3,
			        infinite: true,
			        dots: true
		      	}
		   	},
		    {
		      	breakpoint: 480,
		      	settings: {
			        slidesToShow: 2,
			        slidesToScroll: 1
		      	}
		    }]
		});
	}

	if($testimonialsSlider) {
		$('.testimonials-slider').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false,
			useTransform: true,
			adaptiveHeight: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: true,
			arrows: false
		});
	}

	if($galleryBig) {
		$('.gallery-big').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: true,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			appendArrows: document.querySelector('.gallery-big-arrows'),
		});
	}

	if($sliderBox && $('.slider-box-item').length > 1) {
		$('.slider-box').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: true,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>'
		});
	}

}
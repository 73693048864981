import {bindEvent} from '../eventHandlers/eventHandler';
var _throttle = require('lodash.throttle');
import * as initOdometer from '../functions/initOdometer';

export function init() {

	function scrollInit() {
		initOdometer.init();
	}

	bindEvent(window, 'scroll', _throttle(scrollInit, 300));
};